:root {
    .color-scheme-tru {
        --av-app-base: #fff;
        --av-brand-accent: #3b3b3b1a;
        --av-brand-light: #3b3b3b4d;
        --av-brand-lightest: #3b3b3b0d;
        --av-brand-primary: #f00;
        --av-brand-secondary-accent: #ff74781a;
        --av-brand-secondary-light: #3b3b3b4d;
        --av-brand-secondary: #f00;
        --av-button-action-active: #d30a0a;
        --av-button-action-hover: #ea3f3f;
        --av-button-action-light: #f20C0C4d;
        --av-button-action: #f00;
        --av-button-inversed-active: #d30a0a;
        --av-button-inversed-hover: #ff7478;
        --av-button-inversed-light: #e76a6a4d;
        --av-button-inversed: #e76a6a;
        --av-button-main-active: #3b3b3b;
        --av-button-main-hover: #212122;
        --av-button-main-light: #2121224d;
        --av-button-main: #212122;
        --av-button-secondary-active: #ff585d;
        --av-button-secondary-hover: #ff7478;
        --av-button-secondary-light: #ffadad;
        --av-button-secondary: #e76a6a;
        --av-dark: #243143e6;
        --av-fixed-danger-accent: #ffecec;
        --av-fixed-focus: #ea3f3f;
        --av-fixed-info-accent: #f2f2f2;
        --av-fixed-info-light: #ff7478;
        --av-fixed-invisible: #243143b3;
        --av-fixed-light: #24314366;
        --av-fixed-lighter: #24314366;
        --av-fixed-lightest: #24314333;
        --av-fixed-primary: #243143;
        --av-fixed-secondary: #243143e6;
        --av-fixed-success-dark: #d30a0a;
        --av-fixed-success: #d30a0a;
        --av-inversed-invisible: #ffffff1a;
        --av-inversed-light: #ffffffb3;
        --av-inversed-lighter: rgb(255, 255, 255, .4)
        --av-inversed-lightest: rgb(255, 255, 255, .2)
        --av-inversed-primary: #fff;
        --av-inversed-secondary: #fffffff2;
        --av-link-inversed-active: #d30a0a;
        --av-link-inversed-hover: #ff7478;
        --av-link-inversed-light: #e76a6a4d;
        --av-link-inversed: #ffadad;
        --av-link-primary-active: #d30a0a;
        --av-link-primary-hover: #ff7478;
        --av-link-primary-light: #e76a6a4d;
        --av-link-primary: #d30a0a;
        --av-link-secondary-active: #212122;
        --av-link-secondary-hover: #2C2626;
        --av-link-secondary-light: #f20c0c4d;
        --av-link-secondary: #212122;
        --av-nav-primary: #212122;
        --av-nav-secondary: #f00;
        --av-solid-brand-accent: #f2f2f2;
        --av-solid-brand-lightest: #fbfbfb;
        --av-solid-fixed-invisible: #e9eaec;
    }
}
