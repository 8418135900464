/* stylelint-disable declaration-block-single-line-max-declarations */
$spaceAmounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40);

@each $space in $spaceAmounts {
    $margin: calc($space * 4px) !important;
    .a-ma-#{$space} {margin: $margin}
    .a-mt-#{$space} {margin-top: $margin}
    .a-mr-#{$space} {margin-inline-end: $margin}
    .a-mb-#{$space} {margin-bottom: $margin}
    .a-ml-#{$space} {margin-inline-start: $margin}
    .a-mx-#{$space} {margin-inline-start: $margin;margin-inline-end: $margin}
    .a-my-#{$space} {margin-top: $margin;margin-bottom: $margin}
    .a-pa-#{$space} {padding: $margin}
    .a-pt-#{$space} {padding-top: $margin}
    .a-pr-#{$space} {padding-inline-end: $margin}
    .a-pb-#{$space} {padding-bottom: $margin}
    .a-pl-#{$space} {padding-inline-start: $margin}
    .a-px-#{$space} {padding-inline-start: $margin;padding-inline-end: $margin}
    .a-py-#{$space} {padding-top: $margin;padding-bottom: $margin}
}

.a-ma-auto {margin: auto}
.a-mt-auto {margin-top: auto}
.a-mr-auto {margin-inline-end: auto}
.a-mb-auto {margin-bottom: auto}
.a-ml-auto {margin-inline-start: auto}
.a-mx-auto {margin-inline-start: auto;margin-inline-end: auto}
.a-my-auto {margin-top: auto;margin-bottom: auto}

@media all and (max-width: 1023px) {
    @each $space in $spaceAmounts {
        $margin: calc($space * 4px) !important;
        .a-m-ma-#{$space} {margin: $margin}
        .a-m-mt-#{$space} {margin-top: $margin}
        .a-m-mr-#{$space} {margin-inline-end: $margin}
        .a-m-mb-#{$space} {margin-bottom: $margin}
        .a-m-ml-#{$space} {margin-inline-start: $margin}
        .a-m-mx-#{$space} {margin-inline-start: $margin;margin-inline-end: $margin}
        .a-m-my-#{$space} {margin-top: $margin;margin-bottom: $margin}
        .a-m-pa-#{$space} {padding: $margin}
        .a-m-pt-#{$space} {padding-top: $margin}
        .a-m-pr-#{$space} {padding-inline-end: $margin}
        .a-m-pb-#{$space} {padding-bottom: $margin}
        .a-m-pl-#{$space} {padding-inline-start: $margin}
        .a-m-px-#{$space} {padding-inline-start: $margin;padding-inline-end: $margin}
        .a-m-py-#{$space} {padding-top: $margin;padding-bottom: $margin}
    }

    .a-m-ma-auto {margin: auto}
    .a-m-mt-auto {margin-top: auto}
    .a-m-mr-auto {margin-inline-end: auto}
    .a-m-mb-auto {margin-bottom: auto}
    .a-m-ml-auto {margin-inline-start: auto}
    .a-m-mx-auto {margin-inline-start: auto;margin-inline-end: auto}
    .a-m-my-auto {margin-top: auto;margin-bottom: auto}
}

// FIX: Compatibility with Product Uikit's spacings
$productKitSpaceAmounts: (0, 8, 16, 24);

@each $space in $productKitSpaceAmounts {
    $margin: #{$space}px !important;
    .ma-#{$space} {margin: $margin}
    .mt-#{$space} {margin-top: $margin}
    .mr-#{$space} {margin-inline-end: $margin}
    .mb-#{$space} {margin-bottom: $margin}
    .ml-#{$space} {margin-inline-start: $margin}
    .mx-#{$space} {margin-inline-start: $margin;margin-inline-end: $margin}
    .my-#{$space} {margin-top: $margin;margin-bottom: $margin}
    .pa-#{$space} {padding: $margin}
    .pt-#{$space} {padding-top: $margin}
    .pr-#{$space} {padding-inline-end: $margin}
    .pb-#{$space} {padding-bottom: $margin}
    .pl-#{$space} {padding-inline-start: $margin}
    .px-#{$space} {padding-inline-start: $margin;padding-inline-end: $margin}
    .py-#{$space} {padding-top: $margin;padding-bottom: $margin}
}
