$fontSizes: (40, 42, 48, 56, 64, 70);
$lineHeights: (48, 50, 56, 64, 72, 78);

@media all and (min-width: 1024px) {
    @each $size in $fontSizes {
        .font-size-#{$size} { font-size: #{$size}px !important; }
    }

    @each $height in $lineHeights {
        .line-height-#{$height} { line-height: #{$height}px !important; }
    }
}

@media all and (max-width: 1023px) {
    @each $size in $fontSizes {
        .font-size-m-#{$size} { font-size: #{$size}x !important; }
    }

    @each $height in $lineHeights {
        .line-height-m-#{$height} { line-height: #{$height}px !important; }
    }
}
