/* TODO: do we need it? */

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    height: 100%;
    min-width: 320px;
    color: var(--av-fixed-secondary);
    font: var(--font-default);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

strong {
    font-weight: 600;
}

b {
    font-weight: 700;
}

u {
    text-decoration: underline;
}

em,
i {
    font-style: italic;
}

del {
    text-decoration: line-through;
}

button {
    text-align: start;
    font: var(--font-default);
}

button:focus,
a:focus {
    outline: 0;
}

a {
    cursor: pointer;
}

.a-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 var(--container-paddings-mobile);

    @media (--viewport-mobile-wide) {
        padding-inline-start: var(--container-paddings-mobile-wide);
        padding-inline-end: var(--container-paddings-mobile-wide);
    }

    @media (--viewport-desktop-wide) {
        padding-inline-start: var(--container-paddings-desktop-wide);
        padding-inline-end: var(--container-paddings-desktop-wide);
    }

    @media (--viewport-desktop-large) {
        max-width: 1312px;
        padding-inline-start: var(--container-paddings-desktop-large);
        padding-inline-end: var(--container-paddings-desktop-large);
    }
}

.a-list__item {
    position: relative;
    padding: 0 24px;
}

.a-list__item:not(:last-of-type) {
    margin-bottom: 16px;
}

.a-list_type_bullet .a-list__item::before {
    content: "";
    position: relative;
    top: -0.1em;
    inset-inline-start: -18px;
    margin-inline-end: -4px;
    width: 4px;
    height: 4px;
    display: inline-block;
    vertical-align: middle;
    background-color: currentcolor;
    border-radius: 50%;
}

.a-list_type_number {
    counter-reset: number-list;
    list-style-type: none;
}

.a-list_type_number .a-list__item::before {
    content: counter(number-list) ". ";
    counter-increment: number-list;
    position: relative;
    top: -0.1em;
    inset-inline-start: -1em;
    margin-inline-end: -1em;
    width: 1em;
    display: inline-block;
    vertical-align: middle;
    color: currentcolor;
}

.a-link-list__item:not(:last-of-type) {
    margin-bottom: 16px;
}

.a-link-list__link {
    text-decoration: none;

    color: var(--av-link-primary);
    &:hover {
        color: var(--av-link-primary-hover);
    }
    &:active {
        color: var(--av-link-primary-active);
    }
}

.a-list_type_no-bullet .a-list__item {
    padding: 0;
}

.a-list_type_no-bullet .a-list__item::before {
    display: none;
}

/* Global fix for pegman icon not showing, not altering Gmaps */
.gm-svpc > div:last-of-type {
    width: 32px;
    height: 32px;
}

a.a-link-static {
    color: var(--av-brand-primary);
    text-decoration: none;
    &:hover {
        color: var(--av-brand-secondary);
    }
    &:focus {
        background: var(--av-brand-secondary-light);
        color: var(--av-brand-primary);
    }
    &:active {
        background: none;
        color: var(--av-brand-primary);
    }
}

textarea {
    all: revert;
    padding: 0;
    box-sizing: border-box;
}

.page-container {
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.page {
    @media (--viewport-tablet) {
        flex-grow: 1;
        height: 100%;
    }
}

.wrapper {
    position: relative;
}

#price-calculator + .s-global-footer .a-footer__padded {
    border: none;
}

.el-text--highlighting {
    background: var(--av-fixed-highlight);
}

.el-select-dropdown__wrap {
    margin-block-end: 0 !important;
    margin-inline-end: 0 !important;
}

[dir="rtl"] {
    a[href^="tel:"] .a-link__content {
        direction: ltr;
        unicode-bidi: isolate;
    }
    a[href^="tel:"],
    a.a-link-static[href^="tel:"] {
        direction: ltr;
        unicode-bidi: isolate;
    }
    .a-glyph__arrow,
    .a-glyph__long-arrow {
        transform: rotate(180deg);
    }
    .el-select-dropdown__wrap {
        margin-inline-start: 0 !important;
        margin-inline-end: -17px;
    }
    .el-checkbox__label {
        padding-left: 0;
        padding-inline-start: 8px;
    }
}

/* [WEB-41600] [WEB-42352]: Change font family for unsuporrted locale for Acronis Cyber */
[lang="vi"],
[lang="hi"],
[lang="he"] {
    --ui-kit-font: "Noto Sans", sans-serif;
    --font-family-default: "Noto Sans", sans-serif;
    --font-weight-fineprint-heading: 600;
}

[lang="zh-Hans"] {
    --ui-kit-font: "Noto Sans SC", sans-serif;
    --font-family-default: "Noto Sans SC", sans-serif;
    --font-weight-fineprint-heading: 600;
}
[lang="zh-Hant"] {
    --ui-kit-font: "Noto Sans TC", sans-serif;
    --font-family-default: "Noto Sans TC", sans-serif;
    --font-weight-fineprint-heading: 600;
}
[lang="ja"] {
    --ui-kit-font: "Noto Sans JP", sans-serif;
    --font-family-default: "Noto Sans JP", sans-serif;
    --font-weight-fineprint-heading: 600;
}
[lang="ko"] {
    --ui-kit-font: "Noto Sans KR", sans-serif;
    --font-family-default: "Noto Sans KR", sans-serif;
    --font-weight-fineprint-heading: 600;
}

.draft-warning-banner {
    background-color: var(--av-fixed-danger);
    color: #fff;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.draft-warning-banner > .a-glyph {
    fill: var(--av-inversed-primary);
    transform: translateY(4px);
}

.draft-warning-banner > span {
    display: inline-block;
    margin: 0 10px;
}
