@import '@uikit/ui-kit/themes/acronis/src/common/variables.scss';
@import '@uikit/ui-kit/themes/acronis/src/button.scss';
@import '@uikit/ui-kit/themes/acronis/src/split-button.scss';
@import '@uikit/ui-kit/themes/acronis/src/popover.scss';
@import '@uikit/ui-kit/themes/acronis/src/input.scss';
@import '@uikit/ui-kit/themes/acronis/src/select.scss';
@import '@uikit/ui-kit/themes/acronis/src/date-picker.scss';
@import '@uikit/ui-kit/themes/acronis/src/checkbox.scss';
@import '@uikit/ui-kit/themes/acronis/src/checkbox-button.scss';
@import '@uikit/ui-kit/themes/acronis/src/checkbox-group.scss';
@import '@uikit/ui-kit/themes/acronis/src/dropdown.scss';
@import '@uikit/ui-kit/themes/acronis/src/pagination.scss';
@import '@uikit/ui-kit/themes/acronis/src/radio.scss';
@import '@uikit/ui-kit/themes/acronis/src/radio-button.scss';
@import '@uikit/ui-kit/themes/acronis/src/radio-group.scss';
@import '@uikit/ui-kit/themes/acronis/src/form.scss';
@import '@uikit/ui-kit/themes/acronis/src/form-item.scss';
@import '@uikit/ui-kit/themes/acronis/src/form-error.scss';
@import '@uikit/ui-kit/themes/acronis/src/row.scss';
@import '@uikit/ui-kit/themes/acronis/src/col.scss';
@import '@uikit/ui-kit/themes/acronis/src/card.scss';
@import '@uikit/ui-kit/themes/acronis/src/divider.scss';
@import '@uikit/ui-kit/themes/acronis/src/icon.scss';
@import '@uikit/ui-kit/themes/acronis/src/loading.scss';
@import '@uikit/ui-kit/themes/acronis/src/spinner.scss';
@import '@uikit/ui-kit/themes/acronis/src/autocomplete.scss';
@import '@uikit/ui-kit/themes/acronis/src/search.scss';
@import '@uikit/ui-kit/themes/acronis/src/tooltip.scss';
@import '@uikit/ui-kit/themes/acronis/src/menu.scss';
@import '@uikit/ui-kit/themes/acronis/src/submenu.scss';


/* Manual fixes for v23 migration */
.el-input input::placeholder {
    color: var(--av-fixed-light);
    opacity: 1;
}

.el-select-dropdown-search {
    .el-divider {
        background-color: rgba(38, 104, 197, 0.1);
    }
}
