/* stylelint-disable */
@use "sass:map";
@use "sass:math";

$--breakpoints: (
    'xs': 'max-width: 767px',
    'sm': 'min-width: 768px',
    'md': 'min-width: 1024px',
    'lg': 'min-width: 1280px',
    'xl': 'min-width: 1440px',
);

@mixin res($key, $map: $--breakpoints) {
    @media(#{map.get($map, $key)}) {
        @content;
    }
}

[class*='a-col-'] {
    float: left;
    box-sizing: border-box;
}

.a-form-col {
    float: left;
}

.a-form-col-0 {
    display: none;
}

@for $i from 1 through 24 {
    .a-form-col-#{$i} {
        width: (math.div(1, 24) * $i * 100) * 1%;
    }

    .a-form-col-offset-#{$i} {
        margin-inline-start: (math.div(1, 24) * $i * 100) * 1%;
    }
}

@each $break-point-name in (xs, sm, md, lg, xl) {
    @include res($break-point-name) {

        .a-form-col-#{$break-point-name}-0 {
            display: none;
        }

        @for $i from 1 through 24 {
            .a-form-col-#{$break-point-name}-#{$i} {
                width: (math.div(1, 24) * $i * 100) * 1%;
            }

            .a-form-col-#{$break-point-name}-offset-#{$i} {
                margin-inline-start: (math.div(1, 24) * $i * 100) * 1%;
            }
        }
    }
}
/* stylelint-enable */
