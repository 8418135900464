$maxColumns: 12;

.a-row {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* stylelint can't parse $maxColumns here */

    &.justify-start {
        justify-content: start;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-end {
        justify-content: end;
    }

    &.align-start {
        align-content: start;
    }

    &.align-center {
        align-content: center;
    }

    &.align-end {
        align-content: end;
    }
}

/* stylelint-disable unknown-rule */
@for $span from 1 through $maxColumns {
    .a-col-#{$span} {
        grid-column: span $span;
    }

    .a-col-xs-#{$span} {
        grid-column: span $span;
    }
}

@for $span from 1 through $maxColumns {
    @media all and (min-width: 576px) {
        .a-col-sm-#{$span} {
            grid-column: span $span;
        }
    }
}

@for $span from 1 through $maxColumns {
    @media all and (min-width: 768px) {
        .a-col-md-#{$span} {
            grid-column: span $span;
        }
    }
}

@for $span from 1 through $maxColumns {
    @media all and (min-width: 1024px) {
        .a-col-lg-#{$span} {
            grid-column: span $span;
        }
    }
}

@for $span from 1 through $maxColumns {
    @media all and (min-width: 1280px) {
        .a-col-xl-#{$span} {
            grid-column: span $span;
        }
    }
}

@for $span from 1 through $maxColumns {
    @media all and (min-width: 1440px) {
        .a-col-xxl-#{$span} {
            grid-column: span $span;
        }
    }
}
/* stylelint-enable unknown-rule */
