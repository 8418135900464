:root {
    /* Font-family */
    --font-family-default: 'Acronis Cyber', sans-serif;
    --ui-kit-font: 'Acronis Cyber', sans-serif;
    
    /* Font default */
    --font-default: 400 16px/1.5 var(--font-family-default);

    /* Fingerprint heading font weight */
    --font-weight-fineprint-heading: 500;
}